import UnitDetails, {
    getServerSideProps as gSSP,
} from '@oberoninternal/travelbase-website/dist/components/pages/UnitDetail';
import React, { FC } from 'react';
import { GetStaticPaths, GetStaticProps } from 'next';
import addGlobalQuery from '../../utils/addGlobalQuery';
import USPs from '../../components/USPs';
import Page from '@oberoninternal/travelbase-website/dist/components/Page';
import brandConfig from '../../constants/brandConfig';
import { DEFAULT_REVALIDATION_TIME } from '../../constants/revalidationTime';

const UnitDetailsDE: FC<React.PropsWithChildren<unknown>> = props => (
    <Page>
        <UnitDetails pageSlug="unterkunft" {...props} />
        <USPs />
    </Page>
);
export default UnitDetailsDE;

export const getStaticProps: GetStaticProps = async context => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = await gSSP(brandConfig, context as any);
    return {
        ...(await addGlobalQuery(result, context.locale)),
        revalidate: DEFAULT_REVALIDATION_TIME,
    };
};

export const getStaticPaths: GetStaticPaths = async () => {
    return { paths: [], fallback: 'blocking' };
};
